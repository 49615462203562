import React, { useEffect, useState } from "react"
import { Box, Column, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { ApplicationType } from "@jobintrans/base-ui.types.api"
import { Outlet, useNavigate, useParams } from "react-router-dom"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import styled from "styled-components"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import Button, { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { addSpacesFromPhoneNumber } from "@jobintrans/base-ui.utils.string"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import { useTranslation } from "react-i18next"
import { Pagination } from "@jobintrans/base-ui.components.molecules.pagination"
import LoaderBlock from "components/shared/LoaderBlock"
import { formatDate } from "utils/date"
import { SentryService } from "services/SentryService"
import Link from "components/atoms/Link/Link"
import Switch from "@jobintrans/base-ui.components.atoms.switch"

export const ApplicationBlock = ({
  number,
  hasSurvey,
  application,
}: {
  number: number
  hasSurvey: boolean
  application: ApplicationType
}) => {
  const { offerId = "" } = useParams()

  const { t } = useTranslation(["panel", "data"])
  const [loading, setLoading] = useState(false)
  const [wasRead, setWasRead] = useState(application.wasRead)

  const downloadFile = async () => {
    try {
      setLoading(true)
      const file = await ProfileOfferService.getOfferApplicationFile(`${offerId}`, application.id)
      const pdfBlob = new Blob([file], { type: "application/pdf" })
      const url = window.URL.createObjectURL(pdfBlob)
      const tempLink = document.createElement("a")
      tempLink.href = url
      tempLink.setAttribute(
        "download",
        `${application.firstName.toLowerCase().replaceAll(" ", "-")}_${application.lastName
          .toLowerCase()
          .replaceAll(" ", "-")}.${application.file?.type}`,
      )

      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      window.URL.revokeObjectURL(url)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.getOfferApplicationFile]:", e)
    } finally {
      setLoading(false)
    }
  }

  const handleWasRead = () => {
    try {
      setWasRead(true)
      ProfileOfferService.readOfferApplications(offerId, application.id)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.readOfferApplications]:", e)
    }
  }

  return (
    <ApplicationWrapper hasSurvey={hasSurvey}>
      <Label type="secondary" margin="0">
        {number}.
      </Label>
      <Label margin="0">
        {application.firstName} {application.lastName}
      </Label>
      <Label type="secondary" margin="0">
        {addSpacesFromPhoneNumber(application.phone)}
      </Label>
      <Label type="secondary" margin="0">
        {application.email}
      </Label>
      <Label type="secondary" margin="0" icon={application.citizenship ? `flag-${application.citizenship}` : undefined}>
        {!!application.citizenship && t(`citizenship.${application.citizenship}`, {
          ns: "data"
        })}
      </Label>
      <Label type="secondary" margin="0">
        {formatDate(application.dtCreated)}
      </Label>
      <ApplicationButtons hasSurvey={hasSurvey} wasRead={wasRead}>
        {application.file ? (
          <Button type="secondary" icon="cv" loading={loading} onClick={downloadFile}>
            {t("offers.offer.candidates.download-cv", {
              ns: "panel"
            })}
          </Button>
        ) : (
          <Button type="secondary" disabled icon="cv">
            {t("offers.offer.candidates.no-cv", {
              ns: "panel"
            })}
          </Button>
        )}
        {hasSurvey && (
          <>
            {application.hasSurveyAnswer ? (
              <Link icon="check-square" to={`survey/${application.id}`} type="secondary">
                {t("offers.offer.candidates.survey", {
                  ns: "panel"
                })}
              </Link>
            ) : (
              <Button icon="check-square" type="secondary" disabled>
                {t("offers.offer.candidates.survey", {
                  ns: "panel"
                })}
              </Button>
            )}
          </>
        )}
        {!wasRead && (
          <Box position="relative">
            <IconButton
              iconSize="M"
              size="45px"
              borderRadius="borderRadius"
              icon="check-circle"
              hoverColor="primary"
              onClick={handleWasRead}
            />
            <ReadDot />
          </Box>
        )}
      </ApplicationButtons>
    </ApplicationWrapper>
  )
}

const OfferApplicationModal = () => {
  const { t } = useTranslation(["panel"])
  const navigate = useNavigate()
  const { offerId } = useParams()

  const [isNotRead, setIsNotRead] = useState<boolean>(false)

  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [count, setCount] = useState<number | null>(null)
  const [data, setData] = useState<ApplicationType[]>([])

  const fetchData = async (newPage: number, isNotRead: boolean) => {
    try {
      setLoading(true)
      const { content, totalPages, totalElements } = await ProfileOfferService.getOfferApplications(
        `${offerId}`,
        isNotRead,
        newPage,
      )
      setData(content)
      setTotalPages(totalPages)
      setCount(totalElements)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.getOfferApplications]:", e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchData(page, isNotRead)
  }, [page])

  return (
    <>
      {/*<Modal*/}
      {/*  show={true}*/}
      {/*  setShow={() => navigate("/panel/offers")}*/}
      {/*  type="side"*/}
      {/*  title={t("offers.offer.candidates.title")}*/}
      {/*  text={t("offers.offer.candidates.text")}*/}
      {/*  maxWidth="1200px"*/}
      {/*>*/}
      <Wrapper>
        {!!count && count > 0 && (
          <Row marginBottom="S" justifyContent="space-between" alignItems="flex-end">
            <P margin="0">
              {t("offers.offer.candidates.count")} {count}
            </P>
            <SwitchWrapper
              justifyContent="space-between"
              padding="S"
              background="background"
              alignItems="center"
            >
              <Label marginBottom="0" marginRight="XS">
                {t("offers.offer.candidates.only-new-cadidates")}
              </Label>
              <Switch
                name="paymentContract"
                value={isNotRead}
                onChange={({ name, value }) => {
                  setIsNotRead(value)
                  fetchData(0, value)
                }}
              />
            </SwitchWrapper>
          </Row>
        )}
        {loading ? (
          <Content>
            <LoaderBlock height={67} />
            <LoaderBlock height={67} />
            <LoaderBlock height={67} />
          </Content>
        ) : data.length > 0 ? (
          <>
            <Content>
              {data.map((el, index) => (
                <ApplicationBlock
                  key={`${index}-${el.id}`}
                  number={index + 1 + page * 10}
                  application={el}
                  hasSurvey={!!data.find(el => el.hasSurveyAnswer)}
                />
              ))}
            </Content>
            {data.length > 0 && (
              <Pagination
                defaultPage={page + 1}
                totalPages={totalPages}
                onChange={e => setPage(e - 1)}
              />
            )}
          </>
        ) : (
          <Column alignItems="center">
            <IconBox icon="clock" marginBottom="S" />
            <P textAlign="center" small>
              {t("offers.offer.candidates.empty")}
            </P>
          </Column>
        )}
      </Wrapper>
      {/*</Modal>*/}
      <Outlet />
    </>
  )
}

export default OfferApplicationModal

const ReadDot = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background: ${({ theme }) => theme.color.primary};

  &:before {
    position: absolute;
    left: -5px;
    top: -5px;
    content: "";
    display: block;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background: ${({ theme }) => theme.color.primaryBackground};
    animation-name: scaleAnim;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    @keyframes scaleAnim {
      0% {
        transform: scale(0.5);
      }
      50% {
        transform: scale(1);
      }
      100% {
        transform: scale(0.5);
      }
    }
  }

  &:after {
    position: absolute;
    left: 0px;
    top: 0px;
    content: "";
    display: block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    background: ${({ theme }) => theme.color.primary};
  }
`

const Wrapper = styled(Box)`
  margin-top: ${({ theme }) => theme.space.M};
`

const SwitchWrapper = styled(Row)`
    border-radius: ${({ theme }) => theme.variable.borderRadius};
`;

const Content = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

const ApplicationButtons = styled(Box)<{
  hasSurvey: boolean
  wasRead: boolean
}>`
  display: grid;
  grid-template-columns: ${({ hasSurvey, wasRead }) =>
    hasSurvey ? (!wasRead ? "1fr 1fr 45px" : "1fr 1fr") : !wasRead ? "1fr 45px" : "1fr"};
  grid-gap: ${({ theme }) => theme.space.XS};
`

const ApplicationWrapper = styled(Box)<{
  hasSurvey: boolean
}>`
  position: relative;
  padding: ${({ theme }) => theme.space.XS};
  padding-left: ${({ theme }) => theme.space.S};
  grid-gap: ${({ theme }) => theme.space.XS};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  display: grid;
  grid-template-columns: ${({ hasSurvey }) =>
    hasSurvey ? "30px 1fr 1fr 1fr 1fr 1fr 2fr" : "30px 1fr 1fr 1fr 1fr 1fr 1fr"};
`
