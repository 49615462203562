import React, { useCallback, useState } from "react"
import { GeolocationService, ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { StyledComponentsProvider } from "@jobintrans/base-ui.providers.styled-components"
import AddOfferBasicInfo from "./AddOfferBasicInfo"
import AddOfferAdditionalInfo from "./AddOfferAdditionalInfo"
import AddOfferSummary from "./AddOfferSummary"
import AddOfferPlan from "./AddOfferPlan"
import { updateProfile } from "store/profile"
import { SentryService } from "services/SentryService"
import useExitPrompt from "hooks/useExitPrompt"

const AddOffer = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [showExitPrompt, setShowExitPrompt] = useExitPrompt(false)

  const [loading, setLoading] = useState(false)
  const [step, setStep] = useState(0)
  const [state, setState] = useState<any>({})

  const handleSubmitPlan = useCallback(
    (plan: string, type: string, period: string, code: string) => {
      const newState = {
        ...state,
        plan,
        type,
        period,
        code,
      }
      setStep(1)
      setState(newState)
    },
    [state],
  )

  const handleSetType = useCallback(
    (type: string) => {
      const newState = {
        ...state,
        type,
      }
      setState(newState)
    },
    [state],
  )

  const handleSubmitBasicInfo = useCallback(
    (
      category: string,
      subcategory: string,
      address: any,
      endAddress: object,
      startDate: string,
      endDate: string,
      language: string,
    ) => {
      const newState = {
        ...state,
        category,
        subcategory,
        address,
        endAddress,
        startDate,
        endDate,
        language,
      }
      setStep(2)
      setState(newState)
    },
    [state],
  )

  const handleSubmitAdditionalInfo = useCallback(
    (obj: object) => {
      const newState = {
        ...state,
        ...obj,
      }
      setStep(3)
      setState(newState)
    },
    [state],
  )

  const handleSubmitSummary = useCallback(
    async (paymentMethod: string) => {
      try {
        setShowExitPrompt(false)
        setLoading(true)
        const localization = await GeolocationService.place(
          `${state.address?.id}`,
          `${state.address?.lang}`,
        )
        const isJumper = state.type === "jumper"
        const obj: any = {
          plan: state?.plan,
          period: `period_${state?.period}`,
          type: state.type,
          currency: i18n.language === "pl" ? "PLN" : "EUR",
          paymentMethod: paymentMethod,
          code: state.code,
          category: state?.category,
          language: state?.language,
          subcategory: state?.subcategory,
          name: state.name,
          address: {
            address: localization.address,
            city: localization.city,
            countryCode: localization.country.code,
            provinceCode: localization.province.code,
            postCode: localization.postCode,
            lat: localization.lat,
            lon: localization.lng,
          },
          descriptionDuty: state.switches.description1 ? state.descriptions.description1 : null,
          descriptionRequirements: state.switches.description2
            ? state.descriptions.description2
            : null,
          descriptionWeOffer: state.switches.description3 ? state.descriptions.description3 : null,
          drivingAreas: !isJumper ? state.drivingAreas : [],
          workTypes: !isJumper ? state.workTypes : [],
          truckTypes: state.truckTypes,
          directions: !isJumper ? state.directions : [],
          workLocations: state.workLocations,
          languages: state.languages,
          params: state.params ? state.params.filter((e: string) => e !== "uaFriendly") : [],
          paymentContract: null,
          paymentB2B: null,
          legalInformationClause: state.legalInformationClause,
          legalFutureRecruitment: state.switches.legalFutureRecruitment
            ? state.legalFutureRecruitment
            : null,
          applyLink: state.applyLink && state.applyLink !== "" ? state.applyLink : null,
          surveyId: state.survey ? state.survey.id : null,
          uaFriendly: state.params?.includes("uaFriendly"),
        }

        if (state.endAddress) {
          const endLocalization = await GeolocationService.place(
            `${state.endAddress?.id}`,
            `${state.endAddress?.lang}`,
          )
          obj.endAddress = {
            address: endLocalization.address,
            city: endLocalization.city,
            countryCode: endLocalization.country.code,
            provinceCode: endLocalization.province.code,
            postCode: endLocalization.postCode,
            lat: endLocalization.lat,
            lon: endLocalization.lng,
          }
        }

        if (state.startDate) {
          obj.startDate = state.startDate
        }
        if (state.endDate) {
          obj.endDate = state.endDate
        }

        if (state.switches.paymentContract && state.paymentCheckboxes?.paymentContract) {
          obj.paymentContract = {
            type: "range",
            currency: state.paymentCurrency,
            from: state.paymentContractFrom,
            to: state.paymentContractTo,
          }
        }
        if (state.switches.paymentContract && state.paymentCheckboxes?.paymentContractPerDay) {
          obj.paymentContract = {
            type: "perDay",
            currency: state.paymentCurrency,
            perDay: state.paymentContractPerDay,
          }
        }
        if (state.switches.paymentB2B && state.paymentCheckboxes?.paymentB2B) {
          obj.paymentB2B = {
            type: "range",
            currency: state.paymentCurrency,
            from: state.paymentB2BFrom,
            to: state.paymentB2BTo,
          }
        }
        if (state.switches.paymentB2B && state.paymentCheckboxes?.paymentB2BPerDay) {
          obj.paymentB2B = {
            type: "perDay",
            currency: state.paymentCurrency,
            perDay: state.paymentB2BPerDay,
          }
        }

        const res = await ProfileOfferService.addOffer(obj)
        dispatch(
          updateProfile({
            status: "active",
          }),
        )

        navigate(`/transaction/status/${res.offerId}/${res.transactionId}`)
      } catch (e) {
        window.alert("Nie udało się dodać ogłoszenia! Proszę spróbować później.")
        SentryService.error("[ERROR OfferService.addOffer]:", JSON.stringify(e))
        setShowExitPrompt(true)
      } finally {
        setLoading(false)
      }
    },
    [state, i18n.language],
  )

  let element
  if (step === 0)
    element = <AddOfferPlan state={state} onChange={handleSubmitPlan} setType={handleSetType} />

  if (step === 1)
    element = (
      <AddOfferBasicInfo state={state} onBack={() => setStep(0)} onChange={handleSubmitBasicInfo} />
    )

  if (step === 2)
    element = (
      <AddOfferAdditionalInfo
        onBack={() => setStep(1)}
        state={state}
        onChange={handleSubmitAdditionalInfo}
      />
    )

  if (step === 3)
    element = (
      <AddOfferSummary
        onBack={() => setStep(2)}
        loading={loading}
        state={state}
        onChange={handleSubmitSummary}
      />
    )

  return (
    <StyledComponentsProvider type={state.type === "jumper" ? "jumper" : "primary"}>
      {element}
    </StyledComponentsProvider>
  )
}

export default AddOffer
