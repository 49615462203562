import React from "react";
import { Label } from "@jobintrans/base-ui.components.atoms.typography";

const PlatformLink = ({ status, to }: {
    status: boolean
    to: string
}) => {
    if(status) {
        return <a href={`https://jobintrans.eu${to}`}>
            <Label type="third" color="primary">
                jobintrans.eu{to}
            </Label>
        </a>
    }
    return  <Label type="third" color="red">
        jobintrans.eu{to}
    </Label>
}

export default PlatformLink
