// eslint-disable-next-line import/no-duplicates
import { format } from "date-fns";
// eslint-disable-next-line import/no-duplicates
import pl from "date-fns/locale/pl"
// eslint-disable-next-line import/no-duplicates
import en from "date-fns/locale/en-GB"

export const formatDate = (date: string, lang = "pl", string = "do MMMM yyyy HH:mm") => {
    return format(new Date(date), string, {
        locale: lang === "pl" ? pl : en,
    })
}
