import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { P, Title } from "@jobintrans/base-ui.components.atoms.typography"
import Input from "@jobintrans/base-ui.components.atoms.input"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { ProfileService } from "@jobintrans/base-ui.services.api-service"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { useTranslation } from "react-i18next"
import { RootState } from "store/store"
import { SentryService } from "services/SentryService"

const ProfilePasswordView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["panel", "form", "common", "data"])
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newpassword: "",
      newpassword2: "",
    },
  })

  const [loading, setLoading] = useState(false)
  const [error, setErrorPassword] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    if (el.newpassword !== el.newpassword2) {
      setError("newpassword2", {
        type: "custom",
        message: t("profile.password.different"),
      }, {
        shouldFocus: true
      })
      return
    }

    try {
      setLoading(true)
      setErrorPassword(false)
      await ProfileService.updatePassword(el.newpassword)
      reset()
    } catch (e) {
      SentryService.error("[ERROR ProfileService.updatePassword]:", e)
      setErrorPassword(true)
      return
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Wrapper>
      <Box>
        <Title marginBottom="XS">{t("profile.password.title")}</Title>
        <P small marginBottom="0">
          {t("profile.password.text")}
        </P>
      </Box>
      <Content>
        {error && (
          <Alert type="error" small marginBottom="S">
            {t("profile.password.error")}
          </Alert>
        )}
        <Input
          name="newpassword"
          label={t("profile.password.new-password")}
          type="password"
          error={errors.newpassword}
          rhf={register("newpassword", {
            required: t("required", { ns: "form" }),
            pattern: {
              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
              message: t("wrong-password", { ns: "form" }),
            },
          })}
          marginBottom="S"
        />
        <Input
          name="newpassword2"
          label={t("profile.password.new-password-2")}
          type="password"
          error={errors.newpassword2}
          rhf={register("newpassword2", {
            required: t("required", { ns: "form" }),
            pattern: {
              value: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/,
              message: t("wrong-password", { ns: "form" }),
            },
          })}
          marginBottom="S"
        />
        <Row justifyContent="flex-end">
          <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
            {t("save", { ns: "common" })}
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default ProfilePasswordView

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Content = styled(Box)`
  width: 100%;
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
