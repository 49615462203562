import React, { useCallback } from "react"
import styled from "styled-components"

import { Label } from "@jobintrans/base-ui.components.atoms.typography"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import Icon from "@jobintrans/base-ui.components.atoms.icon"
import { useTranslation } from "react-i18next"
import { logout } from "store/auth"
import { initProfileState } from "store/profile"
import Link from "components/atoms/Link/Link"
import LOGO from "assets/images/logo.svg"
import { RootState } from "store/store"
import Select from "@jobintrans/base-ui.components.atoms.select"
import { locales } from "utils/lang.utils"
import { localeToIcon } from "utils/flags.utils"

const PanelLayoutNavigation = () => {
  const { t, i18n } = useTranslation(["panel", "common"])
  const dispatch = useDispatch()
  const { user } = useSelector((state: RootState) => state.auth)
  const { profile } = useSelector((state: RootState) => state.profile)

  const handleLogout = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
    dispatch(initProfileState())
  }, [])

  const setLanguage = useCallback((lang: string) => {
    LocalStorageService.setObject("lang", lang)
    i18n.changeLanguage(lang)
  }, [])

  return (
    <Wrapper>
      <LogoWrapper>
        <img src={LOGO} />
      </LogoWrapper>
      <NavigationWrapper>
        {user?.role === "ROLE_ADMIN" ? (
          <>
            <Link
              icon="company"
              to="/panel/profiles"
              activeBackground="primaryDarker"
              justifyContent="flex-start"
            >
              Profile
            </Link>
            <Link
              icon="copy"
              to="/panel/offers"
              activeBackground="primaryDarker"
              justifyContent="flex-start"
            >
              Ogłoszenia
            </Link>
            {/*<Link icon="tag-earnings" to="/panel/payments" justifyContent="flex-start">*/}
            {/*  Transakcje*/}
            {/*</Link>*/}
            <Border />
            <Link
              icon="tag-ai"
              to="/panel/tools"
              activeBackground="primaryDarker"
              justifyContent="flex-start"
            >
              Narzędzia
            </Link>
          </>
        ) : (
          <>
            <Link
              icon="edit"
              to="/panel/offers"
              activeBackground="primaryDarker"
              justifyContent="flex-start"
            >
              {t("navigation.offers")}
            </Link>
            <Link
              icon="company"
              to="/panel/profile"
              activeBackground="primaryDarker"
              justifyContent="flex-start"
            >
              {t("navigation.profile")}
            </Link>
            {/*<Link icon="sallary" to="/panel/billing" justifyContent="flex-start">*/}
            {/*  Płatności i faktury*/}
            {/*</Link>*/}
          </>
        )}
      </NavigationWrapper>
      {user?.role === "ROLE_PROFILE" ? (
        <>
          <HelperWrapper>
            <Icon icon="help" color="white" size="L" />
            <Box marginLeft="XS">
              <Label type="secondary" color="white">
                {t("support.text")}
              </Label>
              <Box marginTop="S">
                <a href="tel:+48530207961">
                  <Label type="secondary" color="fourth">
                    +48 530 207 961
                  </Label>
                </a>
              </Box>
              <Box marginTop="XS">
                <a href="mailto:pomoc@jobintrans.eu">
                  <Label type="secondary" color="white">
                    pomoc@jobintrans.eu
                  </Label>
                </a>
              </Box>
            </Box>
          </HelperWrapper>
          <Select
            name="lang"
            options={locales.map(el => [
              `${localeToIcon[el]} ${t(`languages.${el}`, { ns: "common" })}`,
              el,
            ])}
            value={i18n.language}
            onChange={({ value }) => setLanguage(value)}
            color="white"
            background="rgba(255, 255, 255, 0.2)"
            borderColor="primary"
            marginBottom="S"
          />
          <ProfileWrapper>
            <ProfileContent>
              <ProfileImageWrapper>
                {profile?.logo && <img src={profile?.logo} />}
              </ProfileImageWrapper>
              <ProfileName color="white" margin="0" marginLeft="S">
                {`${
                  profile?.name && profile?.name.length > 13
                    ? `${profile?.name.substring(0, 13)}...`
                    : profile?.name
                }`}
              </ProfileName>
            </ProfileContent>
            <IconButton icon="logout" background="none" color="white" onClick={handleLogout} />
          </ProfileWrapper>
        </>
      ) : (
        <ProfileWrapper>
          <ProfileContent>
            <Label color="white" margin="0" marginLeft="S">
              {`${user?.username}`}
            </Label>
          </ProfileContent>
          <IconButton icon="logout" background="none" color="white" onClick={handleLogout} />
        </ProfileWrapper>
      )}
    </Wrapper>
  )
}

export default PanelLayoutNavigation

const HelperWrapper = styled.div`
  padding: ${({ theme }) => theme.space.S};
  background: rgba(255, 255, 255, 0.2);
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  margin-bottom: ${({ theme }) => theme.space.S};
  display: flex;
`

const NavigationWrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XXS};
`

const ProfileName = styled(Label)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const ProfileImageWrapper = styled.div`
  height: 50px;
  width: 50px;
  min-width: 50px;
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  padding: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`

const ProfileContent = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1;
`

const Border = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.2);
`

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  padding: ${({ theme }) => theme.space.S} 0;
`

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.space.M} 0;

  img {
    width: 80%;
    max-height: 100px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 300px;
  height: 100%;
  background: ${({ theme }) => theme.color.primary};
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.S};
`
