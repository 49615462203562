import React from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { AdminOfferType } from "@jobintrans/base-ui.types.api"
import { translate } from "@jobintrans/base-ui.data"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { useNavigate } from "react-router-dom"
import Status from "components/atoms/Status/Status"
import Tags from "components/shared/Tags";

const AdminProfileBlock = ({ data }: { data: AdminOfferType }) => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Content>
        <Box>
          <Row justifyContent="space-between" marginBottom="XS">
            <Row alignItems="center">
              <Status label={data.status} marginRight="XS" />
              <LabelH2 marginBottom="0" marginTop="0">
                {data.name}
              </LabelH2>
            </Row>
            <IconButton icon="edit" background="none" onClick={() => navigate(data.id)} />
          </Row>
          <Tags>
            <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="user"
                iconColor="primary"
                label={data.profile.name}
            />
            {data && (
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="tag"
                iconColor="primary"
                label={`${translate(data.category)} / ${translate(data.subcategory)}`}
              />
            )}
          </Tags>
        </Box>
      </Content>
    </Wrapper>
  )
}

export default AdminProfileBlock

const Content = styled(Box)``

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
  background: white;
`
