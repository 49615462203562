import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex"
import styled from "styled-components"
import { Grid } from "@jobintrans/base-ui.components.atoms._atoms"
import { useTranslation } from "react-i18next"
import { SegmentService } from "@jobintrans/base-ui.services.segment-service"
import { SentryService } from "services/SentryService"

const ToolAddToNewsletter = () => {
  const { t } = useTranslation(["login", "common", "form"])
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
  })

  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    const { firstName, lastName, email } = el
    try {
      await SegmentService.identify(email, {
        firstName,
        lastName,
        email,
        language: "pl",
        accept_newsletter: "true",
        lists: ["business"],
      })
      setResponse("success")
    } catch (e) {
      SentryService.error("[ERROR SegmentService.identify]:", e)
      setResponse("error")
      return
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Accordion title="Dodaj do newslettera">
      {response === "error" && (
        <Alert type="error" small marginBottom="S">
          Nie udało się dodać kontaktu do newslettera.
        </Alert>
      )}
      {response === "success" && (
        <Alert type="success" small marginBottom="S">
          Pomyślnie dodano kontakt do newslettera.
        </Alert>
      )}
      <StyledGrid>
        <Input
          name="name"
          label="Imię*"
          error={errors?.firstName}
          value={watch("firstName")}
          rhf={register("firstName", {
            required: t("required", { ns: "form" }),
          })}
        />
        <Input
          name="name"
          label="Nazwisko"
          error={errors?.lastName}
          value={watch("lastName")}
          rhf={register("lastName", {
            required: false,
          })}
        />
      </StyledGrid>
      <Input
        name="email"
        label="E-mail*"
        error={errors.email}
        rhf={register("email", {
          required: t("required", { ns: "form" }),
          pattern: {
            value: EMAIL_REGEX,
            message: "Niepoprawny format e-mail",
          },
        })}
        marginBottom="S"
      />
      <Button marginLeft="auto" icon="plus" loading={loading} onClick={handleSubmit(onSubmit)}>
        Dodaj
      </Button>
    </Accordion>
  )
}

export default ToolAddToNewsletter

const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
  margin-bottom: ${({ theme }) => theme.space.S};

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`
