import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { P, Title } from "@jobintrans/base-ui.components.atoms.typography"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Select from "@jobintrans/base-ui.components.atoms.select"
import { COUNTRIES_ARRAY } from "@jobintrans/base-ui.data"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { ProfileService } from "@jobintrans/base-ui.services.api-service"
import { useTranslation } from "react-i18next"
import { setProfile } from "store/profile"
import { RootState } from "store/store"
import { SentryService } from "services/SentryService"
import { useSearchCompanyByNumber } from "hooks/useSearchCompanyByNumber"

const ProfileCompanyView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["panel", "form", "common", "data"])
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile?.company?.name || "",
      address: profile?.company?.address || "",
      city: profile?.company?.city || "",
      postCode: profile?.company?.postCode || "",
      countryCode: profile?.company?.countryCode || "",
      number: profile?.company?.number || "",
    },
  })

  const name = watch("name")
  const address = watch("address")
  const city = watch("city")
  const postCode = watch("postCode")
  const countryCode = watch("countryCode")
  const number = watch("number")

  const [isLoadingCompany, isValidCompany] = useSearchCompanyByNumber(countryCode, number, (e) => {
    setValue("name", e.name || name)
    setValue("address", e.address || address)
    setValue("city", e.city || city)
    setValue("postCode", e.postCode || postCode)
  })

  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    try {
      setLoading(true)
      const profile = await ProfileService.updateCompany(el)
      dispatch(setProfile(profile))
    } catch (e) {
      SentryService.error("[ERROR ProfileService.updateCompany]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Wrapper>
      <Box>
        <Title marginBottom="XS">{t("profile.company.title")}</Title>
        <P small marginBottom="0">
          {t("profile.company.text")}
        </P>
      </Box>
      <Content>
        <StyledGrid marginBottom="S">
          <Select
            name="company.countryCode"
            label={`${t("country", { ns: "form" })}*`}
            options={COUNTRIES_ARRAY.map(el => [t(`localization.${el[1]}`, { ns: "data" }), el[1]])}
            error={errors.countryCode}
            value={watch("countryCode")}
            rhf={register("countryCode", {
              required: t("required", { ns: "form" }),
            })}
          />
          <Input
            name="number"
            label={`${t("company.number", { ns: "form" })}*`}
            elementType={countryCode === "PL" ? "pattern" : "text"}
            format="##########"
            startAdornment={countryCode?.toUpperCase()}
            error={errors.number}
            value={watch("number")}
            rhf={register("number", {
              required: t("required", { ns: "form" }),
            })}
            loading={isLoadingCompany}
            success={isValidCompany}
          />
        </StyledGrid>
        <Input
          name="company.name"
          label={`${t("company.name", { ns: "form" })}*`}
          error={errors.name}
          rhf={register("name", {
            required: t("required", { ns: "form" }),
          })}
          marginBottom="S"
        />
        <Input
          name="company.address"
          label={`${t("address", { ns: "form" })}*`}
          error={errors.address}
          rhf={register("address", {
            required: t("required", { ns: "form" }),
          })}
          marginBottom="S"
        />
        <StyledGrid marginBottom="S">
          <Input
            name="company.city"
            label={`${t("city", { ns: "form" })}*`}
            error={errors.city}
            rhf={register("city", {
              required: t("required", { ns: "form" }),
            })}
          />
          <Input
            name="company.postCode"
            label={`${t("postCode", { ns: "form" })}*`}
            elementType={countryCode === "PL" ? "pattern" : "text"}
            format="##-###"
            formattedValue={true}
            error={errors.postCode}
            value={watch("postCode")}
            rhf={register("postCode", {
              required: t("required", { ns: "form" }),
            })}
          />
        </StyledGrid>
        <Row justifyContent="flex-end">
          <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
            {t("save", { ns: "common" })}
          </Button>
        </Row>
      </Content>
    </Wrapper>
  )
}

export default ProfileCompanyView

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Content = styled(Box)`
  width: 100%;
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
