import React from "react"
import { Outlet } from "react-router-dom"
import PanelLayoutContent from "components/layout/PanelLayoutContent"

const Offers = () => {
  return (
    <>
      <PanelLayoutContent
        title="Lista ofert"
        text="Lista ofert na platformie"
      >
        <Outlet/>
      </PanelLayoutContent>
    </>
  )
}

export default Offers
