import React, { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import Accordion from "@jobintrans/base-ui.components.molecules.accordion";
import Alert from "@jobintrans/base-ui.components.molecules.alert";
import Input from "@jobintrans/base-ui.components.atoms.input";
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex";
import Button from "@jobintrans/base-ui.components.atoms.button";
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service";
import { SentryService } from "services/SentryService";

const ToolResetPassword = () => {
    const {
        register,
        handleSubmit,
        watch,
        setValue,
        reset,
        unregister,
        formState: { errors, isValid },
    } = useForm()

    const [response, setResponse] = useState("")
    const [loading, setLoading] = useState(false)

    const onSubmit = useCallback(async (el: any) => {
        setLoading(true)
        try {
            await AdminToolsService.resetPassword(el.email)
            setResponse("success")
        } catch (e) {
            SentryService.error("[ERROR AdminToolsService.resetPassword]:", e)
            setResponse("error")
            return
        } finally {
            setLoading(false)
        }
    }, [])

    return (
        <Accordion title="Resetuj hasło do konta">
            {response === "error" && (
                <Alert type="error" small marginBottom="S">
                    Nie udało się wysłać e-maila, spróbuj podać inny adres email.
                </Alert>
            )}
            {response === "success" && (
                <Alert type="success" small marginBottom="S">
                    E-mail został pomyślnie wysłany!
                </Alert>
            )}
            <Input
                name="email"
                label="E-mail*"
                error={errors.email}
                rhf={register("email", {
                    required: true,
                    pattern: {
                        value: EMAIL_REGEX,
                        message: "Niepoprawny format e-mail",
                    },
                })}
                marginBottom="S"
            />
            <Button marginLeft="auto" icon="send" loading={loading} onClick={handleSubmit(onSubmit)}>
                Wyślij
            </Button>
        </Accordion>
    )
}

export default ToolResetPassword
