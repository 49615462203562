import React from "react"
import PanelLayoutContent from "components/layout/PanelLayoutContent"
import AdminTools from "views/Admin/Tools/Tools"

const Tools = () => {

  return (
      <PanelLayoutContent
        title="Narzędzia"
        text="Dodatkowe narzędzia administracyjne"
      >
        <AdminTools />
      </PanelLayoutContent>
  )
}

export default Tools
