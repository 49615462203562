export const countryToIcon: any = {
  pl: "🇵🇱",
  en: "🇬🇧",
  de: "🇩🇪",
  uk: "🇺🇦",
  ro: "🇹🇩",
  ru: "🇷🇺",
  lt: "🇱🇹",
  cz: "🇨🇿",
  sk: "🇸🇰",
  no: "🇳🇴",
  sv: "🇸🇪",
}

export const localeToIcon: any = {
  pl: "🇵🇱",
  en: "🇬🇧",
  de: "🇩🇪",
  uk: "🇺🇦",
  ro: "🇹🇩",
  ru: "🇷🇺",
  lt: "🇱🇹",
  cs: "🇨🇿",
  sk: "🇸🇰",
  no: "🇳🇴",
  sv: "🇸🇪",
}
