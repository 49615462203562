import React, { useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { AdminProfileType } from "@jobintrans/base-ui.types.api"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import AdminProfileBlock from "components/shared/AdminProfileBlock"

const ProfilesList = ({ loading, data }: {
  loading: boolean
  data: AdminProfileType[]
}) => {
  const [edit, setEdit] = useState<AdminProfileType | boolean>(false)

  if (loading)
    return (
      <Wrapper>
        <Row justifyContent="center">
          <Spinner size="30px" />
        </Row>
      </Wrapper>
    )

  return (
    <Wrapper>
      {data.map(el => (
        <AdminProfileBlock key={el.id} data={el} />
      ))}
    </Wrapper>
  )
}

export default ProfilesList

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
