import styled from "styled-components";
import { Box } from "@jobintrans/base-ui.components.atoms._atoms";

export const InfoContent = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.XS};
`

export const InfoWrapper = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.M};
`

export const Wrapper = styled(Box)``
