import React, { useCallback } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import {
  LocalStorageService,
  ProfileService,
  PublicService,
  UserService,
} from "@jobintrans/base-ui.services.api-service"
import { useForm } from "react-hook-form"
import { EMAIL_REGEX } from "@jobintrans/base-ui.utils.regex"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import Alert from "@jobintrans/base-ui.components.molecules.alert";
import { useTranslation } from "react-i18next";
import { ChangeLanguageComponent, LoginHelperText } from "components/shared/ModalHelpers";
import { errorLogin, initLogin, successLogin } from "store/auth"
import { setProfile, setProfileIds } from "store/profile"
import { RootState } from "store/store"

const Login = () => {
  const { t } = useTranslation(['login', 'common', 'form']);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  const { isLoggingIn, isLoginError } = useSelector((state: RootState) => state.auth)

  const onSubmit = useCallback(async (el: any) => {
    dispatch(initLogin())
    let user = null
    try {
      user = await PublicService.login(el.username, el.password)
      await LocalStorageService.setObject("token", user.token)
    } catch (e) {
      console.log("[ERROR PublicService.login]:", e)
      LocalStorageService.removeObject("token")
      LocalStorageService.removeObject("profileId")
      dispatch(errorLogin())
      return
    }

    let profileIds = null
    try {
      profileIds = await UserService.getProfiles(user.id)
      dispatch(setProfileIds(profileIds))
    } catch (e) {
      console.log("[ERROR UserService.getProfiles]:", e)
    }

    if (profileIds && profileIds[0]) {
      LocalStorageService.setObject("profileId", profileIds[0])
      try {
        const profile = await ProfileService.getProfile(profileIds[0])
        dispatch(setProfile(profile))
      } catch (e) {
        console.log("[ERROR ProfileService.getProfile[:", e)
      }
    }

    dispatch(successLogin(user))
  }, [])

  const handleRegister = useCallback(() => {
    navigate("/register")
  }, [])

  const handleResetPassword = useCallback(() => {
    navigate("/reset-password")
  }, [])

  return (
    <Modal
      show={true}
      icon="shield"
      title={t("title", { ns: "login" })}
      text={t("text", { ns: "login" })}
      maxWidth="400px"
      showBackground={false}
      helperElement={<ChangeLanguageComponent>
        <LoginHelperText />
      </ChangeLanguageComponent>}
    >
      <>
        {isLoginError && (
          <Alert type="error" small marginBottom="S">
            {t("error", { ns: "login" })}
          </Alert>
        )}
        <Input
          name="username"
          label={`${t('email', { ns: 'form' })}*`}
          error={errors.username}
          rhf={register("username", {
            required: t('required', { ns: 'form' }),
            pattern: {
              value: EMAIL_REGEX,
              message: t('wrong-email', { ns: 'form' }),
            },
          })}
          marginBottom="S"
        />
        <Input
          name="password"
          label={`${t('password', { ns: 'form' })}*`}
          type="password"
          error={errors.password}
          rhf={register("password", {
            required: t('required', { ns: 'form' })
          })}
        />
        <Button
          type="third"
          marginBottom="M"
          marginTop="XS"
          marginLeft="auto"
          padding="0"
          onClick={handleResetPassword}
        >
          {t('forgot-password', { ns: 'login' })}
        </Button>
        <Button
          width="100%"
          marginBottom="XS"
          loading={isLoggingIn}
          onClick={handleSubmit(onSubmit)}
        >
          {t('login', { ns: 'common' })}
        </Button>
        <Button type="third" width="100%" onClick={handleRegister}>
          {t('register', { ns: 'common' })}
        </Button>
      </>
    </Modal>
  )
}

export default Login
