import React, { createContext, useContext, useEffect, useState } from "react"
import i18next from "i18next"
import { I18nextProvider } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"

import styled from "styled-components";
import Spinner from "@jobintrans/base-ui.components.atoms.spinner";
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service";

import common_pl from "lang/pl/common.json"
import form_pl from "lang/pl/form.json"
import login_pl from "lang/pl/login.json"
import register_pl from "lang/pl/register.json"
import add_offer_pl from "lang/pl/add-offer.json"
import data_pl from "lang/pl/data.json"
import panel_pl from "lang/pl/panel.json"

import common_en from "lang/en/common.json"
import form_en from "lang/en/form.json"
import login_en from "lang/en/login.json"
import register_en from "lang/en/register.json"
import add_offer_en from "lang/en/add-offer.json"
import data_en from "lang/en/data.json"
import panel_en from "lang/en/panel.json"

import common_de from "lang/de/common.json"
import form_de from "lang/de/form.json"
import login_de from "lang/de/login.json"
import register_de from "lang/de/register.json"
import add_offer_de from "lang/de/add-offer.json"
import data_de from "lang/de/data.json"
import panel_de from "lang/de/panel.json"

import common_lt from "lang/lt/common.json"
import form_lt from "lang/lt/form.json"
import login_lt from "lang/lt/login.json"
import register_lt from "lang/lt/register.json"
import add_offer_lt from "lang/lt/add-offer.json"
import data_lt from "lang/lt/data.json"
import panel_lt from "lang/lt/panel.json"

import common_no from "lang/no/common.json"
import form_no from "lang/no/form.json"
import login_no from "lang/no/login.json"
import register_no from "lang/no/register.json"
import add_offer_no from "lang/no/add-offer.json"
import data_no from "lang/no/data.json"
import panel_no from "lang/no/panel.json"

import common_cs from "lang/cs/common.json"
import form_cs from "lang/cs/form.json"
import login_cs from "lang/cs/login.json"
import register_cs from "lang/cs/register.json"
import add_offer_cs from "lang/cs/add-offer.json"
import data_cs from "lang/cs/data.json"
import panel_cs from "lang/cs/panel.json"

import common_ru from "lang/ru/common.json"
import form_ru from "lang/ru/form.json"
import login_ru from "lang/ru/login.json"
import register_ru from "lang/ru/register.json"
import add_offer_ru from "lang/ru/add-offer.json"
import data_ru from "lang/ru/data.json"
import panel_ru from "lang/ru/panel.json"
import { locales } from "utils/lang.utils"

i18next.use(LanguageDetector).init({
  lng: "pl",
  resources: {
    pl: {
      "common": common_pl,
      "form": form_pl,
      "login": login_pl,
      "register": register_pl,
      "add-offer": add_offer_pl,
      "data": data_pl,
      "panel": panel_pl,
    },
    en: {
      "common": common_en,
      "form": form_en,
      "login": login_en,
      "register": register_en,
      "add-offer": add_offer_en,
      "data": data_en,
      "panel": panel_en,
    },
    de: {
      "common": common_de,
      "form": form_de,
      "login": login_de,
      "register": register_de,
      "add-offer": add_offer_de,
      "data": data_de,
      "panel": panel_de,
    },
    lt: {
      "common": common_lt,
      "form": form_lt,
      "login": login_lt,
      "register": register_lt,
      "add-offer": add_offer_lt,
      "data": data_lt,
      "panel": panel_lt,
    },
    no: {
      "common": common_no,
      "form": form_no,
      "login": login_no,
      "register": register_no,
      "add-offer": add_offer_no,
      "data": data_no,
      "panel": panel_no,
    },
    cs: {
      "common": common_cs,
      "form": form_cs,
      "login": login_cs,
      "register": register_cs,
      "add-offer": add_offer_cs,
      "data": data_cs,
      "panel": panel_cs,
    },
    ru: {
      "common": common_ru,
      "form": form_ru,
      "login": login_ru,
      "register": register_ru,
      "add-offer": add_offer_ru,
      "data": data_ru,
      "panel": panel_ru,
    },
  },
  interpolation: { escapeValue: false }, // React already does escaping
})

export const LocaleContext = createContext<{
  locale: string
}>({
  locale: "pl",
})

export const LocaleContextProvider = ({
  locale,
  children,
}: {
  locale: string
  children: React.ReactNode
}) => {
  const query = new URLSearchParams(window.location.search);

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetchLang = async () => {
      setLoading(true)
      const lang = query.get("lang") || await LocalStorageService.getObject("lang")
      if(locales.includes(lang)){
        LocalStorageService.setObject("lang", lang)
        i18next.changeLanguage(lang)
      }

      setLoading(false)
    }

    fetchLang()
  }, [])

  if (loading) {
    return (
        <InitWrapper>
          <Spinner />
        </InitWrapper>
    )
  }

  return <I18nextProvider i18n={i18next}>{children}</I18nextProvider>
}

export const useLocale = () => useContext(LocaleContext)


const InitWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.8);
`
