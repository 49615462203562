import React, { useState } from "react"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { useNavigate } from "react-router-dom"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import styled from "styled-components"
import { Label, P } from "@jobintrans/base-ui.components.atoms.typography"
import { useTranslation } from "react-i18next"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import Button, { IconButton } from "@jobintrans/base-ui.components.atoms.button"
import { useFieldArray, useForm } from "react-hook-form"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Select from "@jobintrans/base-ui.components.atoms.select"
import { AddSurveyQuestionType } from "@jobintrans/base-ui.types.api"
import { v4 as uuidv4 } from "uuid"
import { ProfileSurveyService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"

type AddSurveyQuestionTypeWithId = AddSurveyQuestionType & {
  id: string
}

const AddQuestionBlock = ({ onSubmit, onCancel }: any) => {
  const { t, i18n } = useTranslation(["panel", "form", "common"])
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    formState: { errors, isValid },
  } = useForm()
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "answers",
  })

  const [options, setOptions] = useState<string[]>()

  const type = watch("type")
  const isRange = type === "range"
  const hasOptions = type === "radio" || type === "checkboxes" || type === "select"

  const onSubmitFunc = (el: any) => {
    const obj: any = {
      id: uuidv4(),
      title: el.title,
      type: el.type,
    }

    if (hasOptions) {
      obj.params = {
        answers: el.answers,
      }
    }

    if (isRange) {
      if (Number(el.from) > Number(el.to)) {
        setError("from", {
          type: "custom",
          message: t("invalid", { ns: "form" }),
        }, {
          shouldFocus: true
        })
        return;
      }

      obj.params = {
        from: el.from,
        to: el.to,
      }
    }

    onSubmit(obj)
  }

  return (
    <QuestionWrapper>
      <form onSubmit={handleSubmit(onSubmitFunc)}>
        <Input
          name="name"
          label={`${t("profile.surveys.questions.title")}*`}
          error={errors.title}
          rhf={register("title", {
            required: t("required", { ns: "form" }),
          })}
        />
        <QuestionContent>
          <Select
            name="type"
            label={`${t("profile.surveys.questions.type")}*`}
            options={[
              [t("profile.surveys.types.short_text"), "short_text"],
              [t("profile.surveys.types.long_text"), "long_text"],
              [t("profile.surveys.types.yes_no"), "yes_no"],
              [t("profile.surveys.types.range"), "range"],
              [t("profile.surveys.types.radio"), "radio"],
              [t("profile.surveys.types.checkboxes"), "checkboxes"],
              [t("profile.surveys.types.select"), "select"],
            ]}
            error={errors.type}
            value={watch("type")}
            rhf={register("type", {
              required: t("required", { ns: "form" }),
            })}
          />
          <Box>
            {hasOptions && (
              <>
                <Label type="secondary" marginBottom="XS">
                  {t("profile.surveys.questions.options")}*
                </Label>
                {fields.map((field, index) => (
                  <Row key={index} alignItems="center" marginBottom="XS" width="100%">
                    <Box width="100%" marginRight="XS">
                      <Input
                        name={`answers.${index}`}
                        rhf={register(`answers.${index}`, {
                          required: t("required", { ns: "form" }),
                        })}
                      />
                    </Box>
                    <IconButton
                      iconSize="M"
                      background="backgroundDarker"
                      icon="trash"
                      color="red"
                      onClick={() => remove(index)}
                    />
                  </Row>
                ))}
                <Button
                  width="100%"
                  background="backgroundDarker"
                  icon="plus"
                  type="secondary"
                  onClick={() => append("")}
                >
                  {t("add", { ns: "common" })}
                </Button>
              </>
            )}
            {isRange && (
              <>
                <Label type="secondary" marginBottom="XS">
                  {t("profile.surveys.questions.options")}*
                </Label>
                <Input
                  name="from"
                  label={`${t("profile.surveys.questions.from")}*`}
                  elementType="numeric"
                  decimalScale={0}
                  error={errors?.from}
                  rhf={register("from", {
                    required: t("required", { ns: "form" }),
                  })}
                  marginBottom="XS"
                />
                <Input
                  name="to"
                  label={`${t("profile.surveys.questions.to")}*`}
                  elementType="numeric"
                  decimalScale={0}
                  error={errors?.to}
                  rhf={register("to", {
                    required: t("required", { ns: "form" }),
                  })}
                />
              </>
            )}
          </Box>
        </QuestionContent>
        <ButtonsRow>
          <Button type="secondary" onClick={onCancel}>
            {t("cancel", { ns: "common" })}
          </Button>
          <Button submit>{t("add", { ns: "common" })}</Button>
        </ButtonsRow>
      </form>
    </QuestionWrapper>
  )
}

const QuestionBlock = ({
  question,
  onTop,
  onBottom,
  onDelete,
  isFirst,
  isLast,
}: {
  question: AddSurveyQuestionTypeWithId
  onTop: () => void
  onBottom: () => void
  onDelete: () => void
  isFirst: boolean
  isLast: boolean
}) => {
  const { t, i18n } = useTranslation(["panel"])

  let answersString = ""
  if (question.type === "yes_no") {
    answersString = t(`profile.surveys.types.yes_no`)
  } else if (
    question.type === "radio" ||
    question.type === "checkboxes" ||
    question.type === "select"
  ) {
    answersString = question?.params?.answers?.join(", ")
  } else if (question.type === "range") {
    answersString = `${question?.params?.from} - ${question?.params?.to}`
  }

  const hasAnswers = answersString !== ""
  return (
    <QuestionWrapper>
      <Row justifyContent="space-between" marginBottom={hasAnswers ? "XS" : "0"}>
        <Label margin="0">{question.title}</Label>
        <QuestionWrapperShortcuts>
          <Tag
            type="secondary"
            showBorder={false}
            background="backgroundDarker"
            label={t(`profile.surveys.types.${question.type}`)}
          />
          {!isFirst && (
            <IconButton
              iconSize="M"
              background="backgroundDarker"
              icon="arrow-top"
              onClick={onTop}
            />
          )}
          {!isLast && (
            <IconButton
              iconSize="M"
              background="backgroundDarker"
              icon="arrow-bottom"
              onClick={onBottom}
            />
          )}
          <IconButton
            iconSize="M"
            background="backgroundDarker"
            icon="trash"
            color="red"
            onClick={onDelete}
          />
        </QuestionWrapperShortcuts>
      </Row>
      {hasAnswers && (
        <P small margin={0}>
          {t(`profile.surveys.answers`)}: {answersString}
        </P>
      )}
    </QuestionWrapper>
  )
}

const AddSurveyModal = ({ show, setShow }: { show: boolean; setShow: (show: boolean) => void }) => {
  const { t } = useTranslation(["panel", "common"])
  const navigate = useNavigate()
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm()

  const [loading, setLoading] = useState(false)
  const [adding, setAdding] = useState(false)
  const [questions, setQuestions] = useState<AddSurveyQuestionTypeWithId[]>([])

  const handleAddQuestion = (el: AddSurveyQuestionTypeWithId) => {
    setQuestions([...questions, el])
    setAdding(false)
  }

  const handleOnTop = (index: number) => {
    const arr = questions
    const el = arr[index]
    const prev = arr[index - 1]
    arr[index] = prev
    arr[index - 1] = el
    setQuestions([...arr])
  }

  const handleOnBottom = (index: number) => {
    const arr = questions
    const el = arr[index]
    const prev = arr[index + 1]
    arr[index] = prev
    arr[index + 1] = el
    setQuestions([...arr])
  }

  const handleOnDelete = (n: number) => {
    setQuestions(questions.filter((el, index) => index !== n))
  }

  const onSubmit = async (el: any) => {
    try {
      setLoading(true)
      await ProfileSurveyService.addSurvey({
        name: el.name,
        questions: questions,
      })
      setShow(false)
      reset()
      setQuestions([])
    } catch (e) {
      SentryService.error("[ERROR ProfileSurveyService.addSurvey]:", e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      show={show}
      setShow={setShow}
      type="side"
      title={t("profile.surveys.add-survey")}
      text={t("profile.surveys.add-survey-text")}
      maxWidth="800px"
    >
      <>
        <Wrapper>
          <Input
            name="name"
            label={`${t("profile.surveys.questions.name")}*`}
            error={errors.name}
            rhf={register("name", {
              required: t("required", { ns: "form" }),
            })}
            marginBottom="S"
          />
          {questions.map((question, index) => (
            <QuestionBlock
              key={question.id}
              isFirst={index === 0}
              isLast={index === questions.length - 1}
              question={question}
              onTop={() => handleOnTop(index)}
              onBottom={() => handleOnBottom(index)}
              onDelete={() => handleOnDelete(index)}
            />
          ))}
          {adding && (
            <AddQuestionBlock onSubmit={handleAddQuestion} onCancel={() => setAdding(false)} />
          )}
          {!adding && (
            <Button type="secondary" width="100%" icon="plus" onClick={() => setAdding(true)}>
              {t("profile.surveys.questions.add-question")}
            </Button>
          )}
        </Wrapper>
        <Button
          width="100%"
          icon="save"
          marginTop="M"
          disabled={questions.length === 0}
          onClick={handleSubmit(onSubmit)}
          loading={loading}
        >
          {t("save", { ns: "common" })}
        </Button>
      </>
    </Modal>
  )
}

export default AddSurveyModal

const ButtonsRow = styled(Box)`
  display: flex;
  justify-content: flex-end;
  grid-gap: ${({ theme }) => theme.space.S};
`

const QuestionContent = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: ${({ theme }) => theme.space.S};
  margin-bottom: ${({ theme }) => theme.space.S};
  grid-gap: ${({ theme }) => theme.space.S};
`

const QuestionWrapperShortcuts = styled(Row)`
  grid-gap: ${({ theme }) => theme.space.XS};
`

const QuestionWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  background: ${({ theme }) => theme.color.background};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
