import { createSlice } from "@reduxjs/toolkit"
import { LoginType } from "@jobintrans/base-ui.types.api/dist/Auth"

interface AuthState {
  isVerifying: boolean
  isLoggingIn: boolean
  isLoginError: boolean
  isRegisteringIn: boolean
  isRegisterError: boolean
  isAuthenticated: boolean
  user: null | LoginType
}

// Define the initial state using that type
const initialState: AuthState = {
  isVerifying: false,
  isLoggingIn: false,
  isLoginError: false,
  isRegisteringIn: false,
  isRegisterError: false,
  isAuthenticated: false,
  user: null,
}

export const authSlice = createSlice({
  name: "counter",
  initialState: initialState,
  reducers: {
    initLogin: state => {
      state.isLoggingIn = true
      state.isLoginError = false
      state.isAuthenticated = false
      state.user = null
    },
    successLogin: (state, action) => {
      state.isLoggingIn = false
      state.isLoginError = false
      state.isAuthenticated = true
      state.user = action.payload
    },
    errorLogin: state => {
      state.isLoggingIn = false
      state.isLoginError = true
      state.isAuthenticated = false
      state.user = null
    },
    initRegister: state => {
      state.isRegisteringIn = true
      state.isRegisterError = false
      state.isAuthenticated = false
      state.user = null
    },
    successRegister: (state, action) => {
      state.isRegisteringIn = false
      state.isRegisterError = false
      state.isAuthenticated = true
      state.user = action.payload
    },
    errorRegister: state => {
      state.isRegisteringIn = false
      state.isRegisterError = true
      state.isAuthenticated = false
      state.user = null
    },
    initVerify: state => {
      state.isVerifying = true
      state.isAuthenticated = false
      state.user = null
    },
    successVerify: (state, action) => {
      state.isVerifying = false
      state.isAuthenticated = true
      state.user = action.payload
    },
    errorVerify: state => {
      state.isVerifying = false
      state.isAuthenticated = false
      state.user = null
    },
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
    },
    updateUser: (state, action) => {
      state.user = {
        ...state.user,
        ...action.payload,
      }
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  initLogin,
  successLogin,
  errorLogin,
  initRegister,
  successRegister,
  errorRegister,
  initVerify,
  successVerify,
  errorVerify,
  updateUser,
  logout,
} = authSlice.actions

export default authSlice.reducer
