import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"

const ToolDeleteAccount = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      username: "",
    },
  })


  const [response, setResponse] = useState("")
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    const { username } = el
    try {
      await AdminToolsService.deleteAccount(username)
      setResponse("success")
    } catch (e) {
      SentryService.error("[ERROR AdminToolsService.addAd]:", e)
      setResponse("error")
      return
    } finally {
      setLoading(false)
    }
  }, [])

  return (
    <Accordion title="Usuń konto pracownika">
      {response === "error" && (
        <Alert type="error" small marginBottom="S">
          Nie udało się usunąć konta.
        </Alert>
      )}
      {response === "success" && (
        <Alert type="success" small marginBottom="S">
          Konto została pomyślnie usunięte!
        </Alert>
      )}
      <Input
        name="username"
        label="Username"
        error={errors.username}
        rhf={register("username", {
          required: true,
        })}
        marginBottom="S"
      />
      <Button marginLeft="auto" icon="trash" loading={loading} onClick={handleSubmit(onSubmit)}>
        Usuń
      </Button>
    </Accordion>
  )
}

export default ToolDeleteAccount
