import * as Sentry from "@sentry/react"

export const error = (name: string, error: any, extraData?: object) => {
    console.log(name, error)
    error.name = `${name} ${error?.message}`
    Sentry.setExtra("error", JSON.stringify(error))
    Sentry.setExtra("data", JSON.stringify(extraData))
    Sentry.captureException(error)
}

export const SentryService = {
    error,
}
