import React, { useCallback } from "react"
import styled from "styled-components"
import { Outlet } from "react-router-dom"
import { LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { IconBox } from "@jobintrans/base-ui.components.atoms.icon"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { useTranslation } from "react-i18next"
import { LocalStorageService } from "@jobintrans/base-ui.services.api-service";
import PanelLayoutNavigation from "./PanelLayoutNavigation"

const PanelLayout = ({ children }: any) => {
  const { i18n } = useTranslation()

  const setLanguage = useCallback((lang: string) => {
    LocalStorageService.setObject("lang", lang)
    i18n.changeLanguage(lang)
  }, [])

  return (
    <Wrapper>
      <PanelLayoutNavigation />
      <Content>
        <Outlet />
        {/*<LanguageButton>*/}
        {/*  <Label type="secondary">*/}
        {/*    {localeToIcon[i18n.language]} {i18n.language.toUpperCase()}*/}
        {/*  </Label>*/}
        {/*  <LanguageSelect className="select" style={{ display: "none" }}>*/}
        {/*    <Box>*/}
        {/*      {locales.map(lng => (*/}
        {/*        <LanguageSelectButton key={lng} type="third" onClick={() => setLanguage(lng)}>*/}
        {/*          {localeToIcon[lng]} {lng.toUpperCase()}*/}
        {/*        </LanguageSelectButton>*/}
        {/*      ))}*/}
        {/*    </Box>*/}
        {/*  </LanguageSelect>*/}
        {/*</LanguageButton>*/}
      </Content>
      <DisableMobile>
        <IconBox icon="lock" />
        <LabelH2 marginTop="M" textAlign="center">
          Otwórz panel korzystając z urządzenia o wiekszej rozdzielczości
        </LabelH2>
      </DisableMobile>
    </Wrapper>
  )
}

export default PanelLayout

const LanguageButton = styled(Box)`
  padding: 5px 10px;
  background: white;
  box-shadow: ${({ theme }) => theme.variable.boxShadow};
  position: absolute;
  right: 10px;
  bottom: 10px;
  
  &:hover {
    .select {
      display: block !important;
    }
  }
`

const LanguageSelectButton = styled(Button)`
  padding: 10px 0;
  width: 50px;
`

const LanguageSelect = styled(Box)`
  position: absolute !important;
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
  padding-bottom: -10px;
  width: max-content;
  display: none;

  > div {
    position: relative;
    background: white;
    border-radius: ${({ theme }) => theme.variable.borderRadius};
    box-shadow: ${({ theme }) => theme.variable.boxShadow};

    &:before {
      display: block;
      content: "";
      position: absolute;
      height: 15px;
      width: 15px;
      bottom: -7px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      background: white;
      border-radius: 2px;
    }
  }
`

const DisableMobile = styled.div`
  background: white;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: ${({ theme }) => theme.space.M};
  flex-direction: column;
  z-index: 999;

  @media (max-width: 950px) {
    display: flex;
  }
`

const Content = styled.div`
  flex: 1 1;
  height: 100vh;
  overflow: scroll;
`

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background: white;
  display: flex;
`
