import React, { useCallback, useEffect, useRef, useState } from "react"
import { AdminProfileType } from "@jobintrans/base-ui.types.api"
import { AdminProfileService } from "@jobintrans/base-ui.services.api-service"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { useForm } from "react-hook-form"
import Input from "@jobintrans/base-ui.components.atoms.input"
import styled from "styled-components"
import Select from "@jobintrans/base-ui.components.atoms.select"
import { PROFILESTATUS_ARRAY } from "@jobintrans/base-ui.data/dist/ProfileInformations"
import { Outlet } from "react-router-dom"
import { P } from "@jobintrans/base-ui.components.atoms.typography";
import PanelLayoutContent from "components/layout/PanelLayoutContent"
import ProfilesList from "views/Admin/Profiles/ProfilesList"
import { SentryService } from "services/SentryService"

const Profiles = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      q: "",
      ststus: "all",
    },
  })

  const q = watch("q")
  const ststus = watch("ststus")

  const firstRender = useRef<boolean>(true)
  const timeoutId = useRef<number | null>()
  const [addCompany, setAddCompany] = useState(false)
  const [loading, setLoading] = useState(true)
  const [totalCount, setTotalCount] = useState(0)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<AdminProfileType[]>([])

  const fetchProfiles = useCallback(
    async (newPage: number) => {
      try {
        setTotalCount(0)
        setLoading(true)
        const { content, totalElements } = await AdminProfileService.getProfiles(q, ststus, newPage, 99)
        setTotalCount(totalElements)
        setData(content)
      } catch (e) {
        SentryService.error("[ERROR AdminProfileService.getProfiles]:", e)
      } finally {
        setLoading(false)
      }
    },
    [q, ststus],
  )

  useEffect(() => {
    fetchProfiles(page)
  }, [page])

  const handleOnRefresh = () => {
    setPage(0)
    fetchProfiles(0)
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      handleOnRefresh()
    }, 1000)
  }, [q, ststus])

  return (
    <>
      <PanelLayoutContent
        title="Lista profili"
        text="Lista aktualnych profili na platformie"
        button={{
          icon: "plus",
          title: "Dodaj firmę",
          onClick: () => setAddCompany(true),
        }}
      >
        <FiltersWrapper marginBottom="M">
          <Input name="q" label="Szukaj" placeholder="np. nazwa firmy" rhf={register("q")} />
          <Select
            name="ststus"
            label="Status*"
            options={[["Wszystkie", "all"], ...PROFILESTATUS_ARRAY]}
            value={watch("ststus")}
            rhf={register("ststus")}
            minWidth="200px"
          />
        </FiltersWrapper>
        {
          totalCount > 0 &&
            <P>System znalazł <span>{totalCount}</span> profili</P>
        }
        <ProfilesList loading={loading} data={data} />
      </PanelLayoutContent>
      <Outlet />
    </>
  )
}

export default Profiles

const FiltersWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.S};
`
