import React, { useCallback, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { Label, P, Title } from "@jobintrans/base-ui.components.atoms.typography"
import Input from "@jobintrans/base-ui.components.atoms.input"
import { EMAIL_REGEX, WEBSITE_REGEX } from "@jobintrans/base-ui.utils.regex"
import Select from "@jobintrans/base-ui.components.atoms.select"
import {
  PROFILEINDUSTRIE_ARRAY,
  PROFILESIZE_ARRAY,
  TRUCKBRANDS_ARRAY,
} from "@jobintrans/base-ui.data"
import { useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { useDispatch, useSelector } from "react-redux"
import { ProfileService } from "@jobintrans/base-ui.services.api-service"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { useTranslation, Trans } from "react-i18next"
import { RootState } from "store/store"
import { SentryService } from "services/SentryService"
import { setProfile } from "store/profile"
import CheckboxesGroup from "@jobintrans/base-ui.components.atoms.checkboxes-group"

const ProfileView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["panel", "form", "common", "data"])
  const dispatch = useDispatch()
  const { profile } = useSelector((state: RootState) => state.profile)

  const [truckBrands, setTruckBrands] = useState<string[]>(profile?.truckBrands || [])

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile?.name,
      email: profile?.email,
      phone: profile?.phone,
      website: profile?.website,
      description: profile?.description,
      companyIndustrie: profile?.companyIndustrie,
      companySize: profile?.companySize,
    },
  })

  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(
    async (el: any) => {
      try {
        setLoading(true)
        const profile = await ProfileService.update({
          ...el,
          truckBrands: truckBrands,
        })
        dispatch(setProfile(profile))
      } catch (e) {
        SentryService.error("[ERROR ProfileService.update]:", e)
        return
      } finally {
        setLoading(false)
      }
    },
    [truckBrands],
  )

  return (
    <>
      <Wrapper>
        <Box>
          <Title marginBottom="XS">{t("profile.profile.title")}</Title>
          <P small marginBottom="0">
            {t("profile.profile.text")}
          </P>
        </Box>
        <Content>
          {profile?.type === "personal" && (
            <Alert type="warning" marginBottom="S">
              <Trans
                i18nKey={t("profile.profile.change-type")}
                components={{
                  href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
                }}
              />
            </Alert>
          )}
          <Input
            name="name"
            label={
              profile?.type === "company"
                ? `${t("profile.name", { ns: "form" })}*`
                : `${t("fullName", { ns: "form" })}*`
            }
            error={errors.name}
            rhf={register("name", {
              required: t("required", { ns: "form" }),
            })}
            marginBottom="S"
          />
          {profile?.type === "company" ? (
            <>
              <Box marginBottom="S">
                <Input
                  name="slug"
                  label={`${t("slug", { ns: "form" })}*`}
                  value={profile.slug}
                  disabled={true}
                  startAdornment="https://jobintrans.eu/profiles/"
                  marginBottom="XXS"
                />
                <P small>
                  <Trans
                    i18nKey={t("profile.profile.change-slug")}
                    components={{
                      href: (
                        <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />
                      ),
                    }}
                  />
                </P>
              </Box>
              <StyledGrid marginBottom="S">
                <Input
                  name="phone"
                  label={`${t("phone", { ns: "form" })}*`}
                  error={errors?.phone}
                  value={watch("phone")}
                  rhf={register("phone", {
                    required: t("required", { ns: "form" }),
                  })}
                />
                <Input
                  name="email"
                  label={`${t("email", { ns: "form" })}*`}
                  error={errors.email}
                  rhf={register("email", {
                    required: t("required", { ns: "form" }),
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t("wrong-email", { ns: "form" }),
                    },
                  })}
                />
              </StyledGrid>
              <StyledGrid marginBottom="S">
                <Select
                  name="companyIndustrie"
                  label={`${t("profile.size", { ns: "form" })}*`}
                  options={PROFILEINDUSTRIE_ARRAY.map(el => [t(el[1], { ns: "data" }), el[1]])}
                  error={errors.companyIndustrie}
                  value={watch("companyIndustrie")}
                  rhf={register("companyIndustrie", {
                    required: t("required", { ns: "form" }),
                  })}
                />
                <Select
                  name="companySize"
                  label={t("profile.size", { ns: "form" })}
                  options={PROFILESIZE_ARRAY}
                  error={errors.companySize}
                  value={watch("companySize")}
                  rhf={register("companySize")}
                />
              </StyledGrid>
              <Input
                name="website"
                label={t("profile.website", { ns: "form" })}
                error={errors?.website}
                value={watch("website")}
                rhf={register("website", {
                  pattern: {
                    value: WEBSITE_REGEX,
                    message: t("wrong-website", { ns: "form" }),
                  },
                })}
                startAdornment="https://"
                marginBottom="S"
              />
              <Input
                name="description"
                label={t("profile.description", { ns: "form" })}
                error={errors?.description}
                value={watch("description")}
                rhf={register("description")}
                multiline
                rows={6}
                marginBottom="S"
              />
              <Box marginBottom="S">
                <Label type="secondary" marginBottom="XS">
                  {t("truckBrands", {
                    ns: "form",
                  })}
                </Label>
                <CheckboxesGroup
                  name="truckBrands"
                  type="secondary"
                  options={TRUCKBRANDS_ARRAY}
                  value={truckBrands}
                  onChange={({ value }) => setTruckBrands(value)}
                />
              </Box>
            </>
          ) : (
            <>
              <Input
                name="phone"
                label={`${t("phone", { ns: "form" })}*`}
                error={errors?.phone}
                value={watch("phone")}
                rhf={register("phone", {
                  required: t("required", { ns: "form" }),
                })}
                marginBottom="S"
              />
              <Input
                name="email"
                label={`${t("email", { ns: "form" })}*`}
                error={errors.email}
                rhf={register("email", {
                  required: t("required", { ns: "form" }),
                  pattern: {
                    value: EMAIL_REGEX,
                    message: t("wrong-email", { ns: "form" }),
                  },
                })}
                marginBottom="S"
              />
            </>
          )}
          <Row justifyContent="flex-end">
            <Button loading={loading} icon="save" onClick={handleSubmit(onSubmit)}>
              {t("save", { ns: "common" })}
            </Button>
          </Row>
        </Content>
      </Wrapper>
    </>
  )
}

export default ProfileView

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const Content = styled(Box)`
  width: 100%;
  max-width: 750px;
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  border-bottom: 1px solid ${({ theme }) => theme.color.border};
  display: flex;
  justify-content: space-between;
  grid-gap: ${({ theme }) => theme.space.M};

  @media (max-width: 1200px) {
    flex-direction: column;
  }
`
