import React, { useCallback, useEffect, useState } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { ShortOfferType } from "@jobintrans/base-ui.types.api"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import Spinner from "@jobintrans/base-ui.components.atoms.spinner"
import { SentryService } from "services/SentryService"
import OfferBlock from "components/shared/OfferBlock"

const OffersList = () => {
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [data, setData] = useState<ShortOfferType[]>([])

  const fetchOffers = useCallback(async (newPage: number) => {
    try {
      setLoading(true)
      const { content } = await ProfileOfferService.getOffers(newPage)
      setData(content)
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.getOffers]:", e)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchOffers(page)
  }, [page])

  if (loading)
    return (
      <Wrapper>
        <Row justifyContent="center">
          <Spinner size="30px" />
        </Row>
      </Wrapper>
    )

  return (
    <Wrapper>
      {data.map(el => (
        <OfferBlock key={el.id} data={el} />
      ))}
    </Wrapper>
  )
}

export default OffersList

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M} 0;
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
`
