import React from "react";
import styled from "styled-components"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms";
import ToolGenerateCode from "./ToolGenerateCode";
import ToolResetPassword from "./ToolResetPassword";
import ToolSendEmailProfile from "./ToolSendEmailProfile";
import ToolSendSMS from "./ToolSendSMS";
import ToolAddToNewsletter from "./ToolAddToNewsletter";
import ToolAddAd from "./ToolAddAd"
import ToolDeleteAccount from "./ToolDeleteAccount"

const Tools = () => {
    return (
        <Wrapper>
            <Content>
                {/*<ToolSendEmail />*/}
                <ToolSendEmailProfile />
                <ToolResetPassword />
                <ToolDeleteAccount />
                <ToolAddToNewsletter />
            </Content>
            <Content>
                <ToolGenerateCode />
                <ToolAddAd />
                <ToolSendSMS />
            </Content>
        </Wrapper>
    )
}

export default Tools

const Content = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.M};
  `;

const Wrapper = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.M};
`;
