import { useCallback, useEffect, useRef, useState } from "react"
import { PublicService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"
import { ProfileCompanyType } from "@jobintrans/base-ui.types.api"

export const useSearchCompanyByNumber = (countryCode: string, value: string, onSuccess: (e: Partial<ProfileCompanyType>) => void) => {
  const timeoutId = useRef<number | null>()
  const firstTime = useRef(true)

  const [loading, setLoading] = useState(false);
  const [isValid, setIsValid] = useState(false)

  const searchCompanyByNip = useCallback(async (number: string) => {
    try {
      setLoading(true)
      setIsValid(false)
      const res = await PublicService.getCompanyFromGUS(number)
      onSuccess(res)
      setIsValid(true)
    } catch (e) {
      SentryService.error("[ERROR PublicService.getCompanyFromGUS]:", e)
      setIsValid(false)
    } finally {
      setLoading(false)
    }
  }, [])

  const searchCompanyByVIES = useCallback(async (number: string) => {
    try {
      setLoading(true)
      setIsValid(false)
      const res = await PublicService.getCompanyFromVIES(`${countryCode}${number}`)
      onSuccess({
        name: res.traderName,
        countryCode: countryCode,
      })
      setIsValid(true)
    } catch (e) {
      SentryService.error("[ERROR PublicService.getCompanyFromVIES]:", e)
      setIsValid(false)
    } finally {
      setLoading(false)
    }
  }, [countryCode])

  useEffect(() => {
    if(firstTime.current){
      firstTime.current = false
      return
    }

    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }

    // @ts-ignore
    timeoutId.current = setTimeout(() => {
      if(value && value.length > 1){
        if(countryCode === "PL"){
          if (value.length === 10) {
            searchCompanyByNip(value)
          } else {
            setIsValid(false)
          }
        }else {
          searchCompanyByVIES(value)
        }
      }else {
        setIsValid(false)
      }
    }, 1000)
  }, [countryCode, value])

  return [
    loading,
    isValid
  ]
}
