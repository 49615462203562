import React, { useCallback, useState } from "react"
import { useForm } from "react-hook-form"
import Accordion from "@jobintrans/base-ui.components.molecules.accordion"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import Input from "@jobintrans/base-ui.components.atoms.input"
import Select from "@jobintrans/base-ui.components.atoms.select"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { AdminToolsService } from "@jobintrans/base-ui.services.api-service"
import styled from "styled-components"
import { Box, Grid } from "@jobintrans/base-ui.components.atoms._atoms"
import { DiscountType } from "@jobintrans/base-ui.types.api"
import { Label, LabelH1 } from "@jobintrans/base-ui.components.atoms.typography"
import CheckboxesGroup from "@jobintrans/base-ui.components.atoms.checkboxes-group"
import { SentryService } from "services/SentryService"

const ToolGenerateCode = () => {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    unregister,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      code: "",
      valuePercentage: "15",
      valuePost: "1",
      quantity: "1",
      months: "1",
      email: "",
    },
  })

  const [error, setError] = useState(false)
  const [discount, setDiscount] = useState<null | DiscountType>(null)
  const [loading, setLoading] = useState(false)

  const [type, setType] = useState("percentage")
  const [time, setTime] = useState(["m1"])

  const onSubmit = useCallback(async (el: any) => {
    setLoading(true)
    try {
      let body: any = {
        type: type,
        code: el.code,
        time: time,
        quantity: el.quantity,
        months: el.months,
      }

      if(type === "percentage"){
        body = {
          ...body,
          value: el.valuePercentage,
        }
      }else{
        body = {
          ...body,
          value: el.valuePost,
        }
      }

      const discount = await AdminToolsService.addDiscount(el.email,  body)
      setDiscount(discount)
      setError(false)
    } catch (e) {
      SentryService.error("[ERROR AdminToolsService.sendEmail]:", e)
      setError(true)
      return
    } finally {
      setLoading(false)
    }
  }, [type, time])

  const handleCopyCode = () => {
    if ("clipboard" in navigator && discount?.code) {
      navigator.clipboard.writeText(discount.code)
    }
  }

  return (
    <Accordion title="Generuj kod rabatowy">
      {discount ? (
        <>
          <Box marginBottom="S">
            <Label marginBottom="XS">Kod rabatowy:</Label>
            <Code>{discount.code}</Code>
          </Box>
          <Button width="100%" icon="copy" onClick={handleCopyCode} marginBottom="XS">
            Kopiuj kod
          </Button>
          <Button type="secondary" width="100%" onClick={() => setDiscount(null)}>
            Generuj nowy kod
          </Button>
        </>
      ) : (
        <>
          {error && (
            <Alert type="error" small marginBottom="S">
              Nie udało się wygenerować kodu rabatowego.
            </Alert>
          )}
          <Input
            name="code"
            label="Kod rabatowy"
            error={errors?.code}
            value={watch("code")?.toUpperCase()}
            rhf={register("code")}
            marginBottom="S"
          />
          <CheckboxesGroup
            name="type"
            type="secondary"
            options={[
              ["Procenty (%)", "percentage"],
              ["Ogłoszenie", "post"],
            ]}
            value={[type]}
            onChange={({ value }) => setType(value[value.length - 1] ?? type)}
            marginBottom="S"
          />
          <StyledGrid>
            {type === "percentage" ? (
              <Input
                name="value"
                label="Wartość (%)*"
                elementType="numeric"
                error={errors?.valuePercentage}
                value={watch("valuePercentage")}
                rhf={register("valuePercentage", {
                  required: true,
                })}
              />
            ) : (
              <Select
                name="category"
                label="Rodzaj ogłoszenia*"
                options={[
                  ["Podstawowe", "1"],
                  ["Rozszerzone", "2"],
                  ["Premium", "3"],
                ]}
                error={errors.valuePost}
                value={watch("valuePost")}
                rhf={register("valuePost", {
                  required: true,
                })}
              />
            )}
            <Input
              name="quantity"
              label="Ilość użyć*"
              elementType="numeric"
              error={errors?.quantity}
              value={watch("quantity")}
              rhf={register("quantity", {
                required: true,
              })}
            />
            <Select
              name="months"
              label="Okres ważności*"
              options={[
                ["1 miesiąc", "1"],
                ["3 miesiące", "3"],
                ["6 miesięcy", "6"],
              ]}
              error={errors.months}
              value={watch("months")}
              rhf={register("months", {
                required: true,
              })}
            />
          </StyledGrid>
          <Box>
            <Label type="secondary" marginBottom="XS">Możliwe okresy</Label>
            <CheckboxesGroup
                name="time"
                type="secondary"
                options={[
                  ["1 miesiąc", "m1"],
                  ["3 miesiące", "m3"],
                  ["6 miesięcy", "m6"],
                ]}
                value={time}
                onChange={({ value }) => setTime(value.length === 0 ? ["m1"] : value)}
                marginBottom="S"
            />
          </Box>
          <Input
            name="email"
            label="Email"
            error={errors.email}
            rhf={register("email", { required: false })}
            marginBottom="S"
          />
          <Button marginLeft="auto" icon="heart" loading={loading} onClick={handleSubmit(onSubmit)}>
            Generuj kod
          </Button>
        </>
      )}
    </Accordion>
  )
}

export default ToolGenerateCode

const Code = styled(LabelH1)`
  display: block;
  font-size: 65px;
  color: ${({ theme }) => theme.color.primary};
  text-align: center;
  width: 100%;
  margin: 0;
`

const StyledGrid = styled(Grid)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
  margin-bottom: ${({ theme }) => theme.space.S};

  @media (max-width: 1300px) {
    grid-template-columns: 1fr;
  }
`
