import React, { useCallback, useEffect, useState } from "react"
import Modal from "@jobintrans/base-ui.components.molecules.modal"

import { useDispatch } from "react-redux"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"

import { LocalStorageService } from "@jobintrans/base-ui.services.api-service"
import Button from "@jobintrans/base-ui.components.atoms.button"
import { PLANS_ARRAY } from "@jobintrans/base-ui.data"
import PricingSection from "@jobintrans/base-ui.components.sections.pricing-section"
import { useTranslation } from "react-i18next"
import { AddOfferHelperText, ChangeLanguageComponent } from "components/shared/ModalHelpers"
import { logout } from "store/auth"

const AddOfferPlan = ({ onChange, setType }: any) => {
  const { t, i18n } = useTranslation(["add-offer", "common"])
  const dispatch = useDispatch()

  const [code, setCode] = useState("")
  const [params, setParams] = useState<{ code?: string; type?: string; period: string; plan: string }>({
    period: "1",
    type: "offer",
    plan: PLANS_ARRAY["offer"][0].slug,
  })

  const onSubmit = useCallback(async () => {
    onChange(params.plan, params.type, params.period, params.code)
  }, [params.plan, params.type, params.period, params.code])

  const handleLogOut = useCallback(() => {
    LocalStorageService.removeObject("token")
    dispatch(logout())
  }, [])

  const handleChangeParams = useCallback(({ code, type, period, plan }: any) => {
    setParams({
      code,
      type,
      period,
      plan,
    })
    setType(type)
  }, [])

  useEffect(() => {
    const fetchCode = async () => {
      const discount = await LocalStorageService.getObject("code")
      setCode(discount)
      LocalStorageService.removeObject("code")
    }

    fetchCode()
  }, [])
  return (
    <Modal
      show={true}
      icon="plus-circle"
      title={t("title", { ns: "add-offer" })}
      text={t("text", { ns: "add-offer" })}
      maxWidth="1300px"
      showBackground={false}
      mobileFullScreen={true}
      helperElement={
        <ChangeLanguageComponent>
          <AddOfferHelperText />
        </ChangeLanguageComponent>
      }
      helperButton={{
        text: t("logout", { ns: "common" }),
        onClick: handleLogOut,
      }}
      bottomElement={
        <Row justifyContent="flex-end">
          <Button onClick={onSubmit}>{t("next", { ns: "common" })}</Button>
        </Row>
      }
    >
      <PricingSection
        initialCode={code}
        setParams={handleChangeParams}
        currency={i18n.language === "pl" ? "pl" : "eur"}
        translator={e => t(`basket.${e}`, { ns: "add-offer" })}
      />
    </Modal>
  )
}

export default AddOfferPlan

const PromoBannerWrapper = styled(Box)`
  margin-bottom: ${({ theme }) => theme.space.XL};
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DiscountList = styled(Box)`
  display: flex;
  flex-direction: column;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-top: ${({ theme }) => theme.space.S};
  margin-bottom: ${({ theme }) => theme.space.S};
`

const StyledGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
  margin-top: 40px;

  > div:nth-child(2) {
    > div {
      height: 100%;
    }
  }

  @media (max-width: 1000px) {
    margin-top: 0;
    grid-template-columns: 1fr;

    > div:nth-child(2) {
      padding-top: 34px;
    }
  }
`
