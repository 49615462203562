import React, { useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { useTranslation } from "react-i18next"
import PanelLayoutContent from "components/layout/PanelLayoutContent"
import { RootState } from "store/store"
import OffersList from "views/Profile/Offers/OffersList"

const Offers = () => {
  const { t } = useTranslation(["panel", "common"])
  const navigate = useNavigate()
  const { profile } = useSelector((state: RootState) => state.profile)

  const [modal, setModal] = useState<string | boolean>(false)

  return (
    <>
      <PanelLayoutContent
        title={t('offers.list.title')}
        text={t('offers.list.text')}
        button={{
          icon: "plus",
          title: t('offers.list.button'),
          onClick: () => navigate("/add-offer"),
        }}
      >
        <OffersList />
      </PanelLayoutContent>
      <Outlet />
    </>
  )
}

export default Offers
