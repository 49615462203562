import { AdminOfferTransactionType } from "@jobintrans/base-ui.types.api"
import React, { useState } from "react"
import { AdminTransactionsService } from "@jobintrans/base-ui.services.api-service"
import { SentryService } from "services/SentryService"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import Status from "components/atoms/Status/Status"
import { LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { getPlan, translate } from "@jobintrans/base-ui.data"
import { formatPrice } from "@jobintrans/base-ui.utils.number"
import Tags from "components/shared/Tags"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import { formatDate } from "utils/date"
import Button from "@jobintrans/base-ui.components.atoms.button"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const TransactionBlock = ({
  transaction,
  refresh,
}: {
  transaction: AdminOfferTransactionType
  refresh: () => void
}) => {
  const { t } = useTranslation(["add-offer"])

  const [loading, setLoading] = useState(false)

  const paidTransaction = async () => {
    try {
      setLoading(true)
      await AdminTransactionsService.acceptTransaction(transaction.id)
      refresh()
    } catch (e) {
      SentryService.error("[ERROR AdminTransactionsService.acceptTransaction]:", e)
    } finally {
      setLoading(false)
    }
  }

  return (
    <TransactionWrapper>
      <Row marginBottom="XS">
        <Status label={transaction.status} marginRight="XS" />
        <Row justifyContent="space-between" flex={1}>
          {transaction.product.type === "offer_plan" && (
            <LabelH2 margin="0">
              {t(`basket.plans.${getPlan("offer", transaction.product.slug).slug}.title`)} ({transaction.quantity}{" "}
              mies.)
            </LabelH2>
          )}
          <LabelH2 margin="0">
            {formatPrice(transaction.value)} {translate(transaction.currency)}
          </LabelH2>
        </Row>
      </Row>
      <Tags>
        {transaction.discount && (
          <Tag
            type="secondary"
            showBorder={false}
            background="background"
            icon="tag"
            iconColor="primary"
            label={transaction.discount}
          />
        )}
        <Tag
          type="secondary"
          showBorder={false}
          background="background"
          icon="sallary"
          iconColor="primary"
          label={translate(transaction.paymentMethodType)}
        />
        {transaction.dtCreated && (
          <Tag
            type="secondary"
            showBorder={false}
            background="background"
            icon="clock-check"
            iconColor="primary"
            label={formatDate(transaction.dtCreated)}
          />
        )}
      </Tags>
      {transaction.status === "unpaid" && (
        <Box marginTop="S">
          <Button
            width="100%"
            loading={loading}
            background="green"
            hoverBackground="greenDarker"
            icon="check-circle"
            onClick={paidTransaction}
          >
            Oznacz jako opłacone
          </Button>
        </Box>
      )}
    </TransactionWrapper>
  )
}

export default TransactionBlock

const TransactionWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  border: 1px solid ${({ theme }) => theme.color.border};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
`
