import React from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import styled from "styled-components"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { useTranslation } from "react-i18next"
import Link from "components/atoms/Link/Link"
import { RootState } from "store/store"
import PanelLayoutContent from "components/layout/PanelLayoutContent"

const Profile = () => {
  const navigate = useNavigate()
  const { t } = useTranslation(["panel", "common"])
  const { profile } = useSelector((state: RootState) => state.profile)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm()

  return (
    <PanelLayoutContent title={t("profile.title")} text={t("profile.text")}>
      <NavigationWrapper>
        <Link type="secondary" to="/panel/profile" end>
          {t("profile.navigation.profile")}
        </Link>
        <Link type="secondary" to="/panel/profile/surveys">
          {t("profile.navigation.surveys")}
        </Link>
        <Link type="secondary" to="/panel/profile/settings">
          {t("profile.navigation.settings")}
        </Link>
        <Link type="secondary" to="/panel/profile/billing">
          {t("profile.navigation.billing")}
        </Link>
        <Link type="secondary" to="/panel/profile/password">
          {t("profile.navigation.password")}
        </Link>

      </NavigationWrapper>
      <Outlet />
    </PanelLayoutContent>
  )
}

export default Profile

const NavigationWrapper = styled(Box)`
  display: flex;
  grid-gap: ${({ theme }) => theme.space.S};
`
