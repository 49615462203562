import React, { ReactNode } from "react"
import styled from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { P, Title } from "@jobintrans/base-ui.components.atoms.typography"
import Button from "@jobintrans/base-ui.components.atoms.button"

const PanelLayoutContent = ({
  title,
  text,
  children,
  button,
}: {
  title: string
  text: string
  button?: {
    title: string
    icon: string
    onClick: () => void
  }
  children: ReactNode | ReactNode[]
}) => {
  return (
    <Wrapper>
      <Row alignItems="center" justifyContent="space-between">
        <Box>
          <Title type="primary" fontSize="XXXL" marginBottom="XS">
            {title}
          </Title>
          <P marginBottom="0">{text}</P>
        </Box>
        {button && <Button onClick={button.onClick} icon={button.icon}>{button.title}</Button>}
      </Row>
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default PanelLayoutContent

const Content = styled(Box)`
  margin-top: ${({ theme }) => theme.space.M};
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.M};
`
