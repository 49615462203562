import React, { ReactNode } from "react"
import styled from "styled-components"
import { StyledButtonProps, buttonStyle } from "@jobintrans/base-ui.components.atoms.button"
import Icon from "@jobintrans/base-ui.components.atoms.icon"
import { Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { NavLink } from "react-router-dom";

export type LinkProps = StyledButtonProps & {
  children: ReactNode
  type?: "primary" | "secondary" | "third" | "fourth"
  activeBackground?: string
  end?: boolean
  icon?: string
  to: string
  target?: string
}

const Link = ({
  children,
  to,
  type = "primary",
  icon,
  disabled,
  padding = "12px 30px",
  hoverColor,
  hoverBackground,
  activeBackground,
  end,
  target,
  ...rest
}: LinkProps) => {
  return (
    <StyledLink
      to={to}
      styledType={type}
      disabled={disabled}
      padding={padding}
      hoverColor={hoverColor}
      hoverBackground={hoverBackground}
      activeBackground={activeBackground}
      end={end}
      target={target}
      {...rest}
    >
      <Row alignItems="center">
        {icon && <Icon marginRight="XS" icon={icon} />}
        {children}
      </Row>
    </StyledLink>
  )
}

export default Link

const StyledLink = styled(NavLink)<StyledButtonProps & {
  activeBackground?: string
}>`
  ${buttonStyle};
  
  &.active {
    color: ${({ theme }) => theme.color.white};
    background: ${({ theme, activeBackground = "primary" }) => theme.color[activeBackground]};
  }
`
