import React, { ReactNode } from "react";
import { BaseStyles } from "@jobintrans/base-ui.types.base-styles";
import styled from "styled-components";
import { Row } from "@jobintrans/base-ui.components.atoms._atoms";

const Tags = ({ children, ...rest } : BaseStyles & {
    children: ReactNode | ReactNode[]
}) => {

    return  <Wrapper {...rest}>
        {children}
    </Wrapper>
}

export default Tags

export const Wrapper = styled(Row)`
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
`

