import React, { useState } from "react"
import "@draft-js-plugins/static-toolbar/lib/plugin.css"
import Editor from "@draft-js-plugins/editor"
import createToolbarPlugin from "@draft-js-plugins/static-toolbar"
import styled from "styled-components"
import { convertFromHTML, convertToRaw, EditorState, ContentState } from "draft-js"
import { Box } from "@jobintrans/base-ui.components.atoms._atoms"
import { BaseStyles } from "@jobintrans/base-ui.types.base-styles"
import { BoldButton, OrderedListButton, UnorderedListButton } from "@draft-js-plugins/buttons"
import { textHTMLStyles } from "@jobintrans/base-ui.components.atoms.text-html"
import draftToHtml from "draftjs-to-html"

const RichTextEditor = ({
  name,
  value = "",
  onChange,
  ...rest
}: BaseStyles & {
  name: string
  value?: string
  onChange: (event: { target: { name: string; value: string } }) => void
}) => {
  const [{ plugins, Toolbar }] = useState(() => {
    const toolbarPlugin = createToolbarPlugin()
    const { Toolbar } = toolbarPlugin
    const plugins = [toolbarPlugin]
    return {
      plugins,
      Toolbar,
    }
  })

  const blocksFromHTML = convertFromHTML(value)
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap,
  )

  const [state, setState] = useState(EditorState.createWithContent(contentState))

  const handleOnChange = (txt: any) => {
    setState(txt)
    onChange({
      target: {
        name,
        value: draftToHtml(convertToRaw(txt.getCurrentContent())),
      },
    })
  }

  return (
    <Wrapper {...rest}>
      <ToolbarWrapper>
        <Toolbar>
          {externalProps => (
            <div>
              <BoldButton {...externalProps} />
              <UnorderedListButton {...externalProps} />
              <OrderedListButton {...externalProps} />
            </div>
          )}
        </Toolbar>
      </ToolbarWrapper>
      <EditorWrapper marginTop="XS">
        <Editor
          editorState={state}
          onChange={handleOnChange}
          plugins={plugins}
        />
      </EditorWrapper>
    </Wrapper>
  )
}

export default RichTextEditor

const EditorWrapper = styled(Box)`
  ${textHTMLStyles};

    .public-DraftEditor-content {
        min-height: 150px !important;
    }
`

const ToolbarWrapper = styled(Box)`
  > div {
    border-radius: ${({ theme }) => theme.variable.borderRadius};
    border: 1px solid ${({ theme }) => theme.color.border};
    box-shadow: none;
    overflow: hidden;

    button {
      background: none;

      &:hover {
        background: ${({ theme }) => theme.color.background};
      }
    }
  }
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.XS};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
`
